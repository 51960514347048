import React from 'react'
import { Select, Input } from 'antd'
import useTrainingsAdminStore from 'widgets/trainingsListAdmin/TrainingsList/zustand/store'
import { SearchOutlined } from '@ant-design/icons'
import s from './SearchTraining.module.scss'

function SearchTraining() {
	const updateStore = useTrainingsAdminStore((state) => state.updateStore)

	const handleChange = (value: string) => {
		updateStore({
			selectedLang: value,
		})
	}

	const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		updateStore({
			searchStr: e.target.value,
		})
	}

	return (
		<div className={s.wrapper}>
			<div className={s.selectLang}>
				<div>Язык курса:</div>
				<Select
					style={{ width: 120 }}
					defaultValue="ru"
					onChange={handleChange}
				>
					<Select.Option value="ru">Русский</Select.Option>
					<Select.Option value="es">Испанский</Select.Option>
				</Select>
			</div>
			<Input
				placeholder="Название курса"
				onChange={handleOnSearch}
				prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
				allowClear
			/>
		</div>
	)
}

export default SearchTraining
