import { useEffect } from 'react'
import TrainingApiTypes from 'parts/requests/training/trainingApiTypes'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import useTrainingsAdminStore from '../store'
import { TrainingsStoreType } from '../storeTypes'
import { getStatisticTexts } from './getStats'

/** Делает запрос на получение списка школ и текущей школы, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const updateStore = useTrainingsAdminStore((state) => state.updateStore)

	// Получить список курсов
	const { data: trainingsRes } = trainingQuery.getTrainingsForAdmin.useQuery()

	useEffect(
		function () {
			if (!trainingsRes) return

			const trainingsForStore = prepareTrainingsForStore(
				trainingsRes.data
			)

			updateStore({
				trainingsAdmin: trainingsForStore,
				rawTrainings: trainingsRes.data,
				dataLoadingStatus: trainingsRes.data.length
					? 'hasTrainings'
					: 'noTrainings',
			})
		},
		[trainingsRes]
	)
}

/**
 * Получает массив курсов и преобразует его в данные для отрисовки карточки курса
 * @param trainings — массив курсов
 */
function prepareTrainingsForStore(
	trainings: TrainingApiTypes.GetTrainingsForAdmin
): TrainingsStoreType.TrainingCard[] {
	return trainings.map((training) => {
		const { lessonsStr, groupsStr, studentsStr } =
			getStatisticTexts(training)

		return {
			id: training.id,
			cover: training.cover,
			name: training.name,
			description: training.description,
			lessonsStr,
			groupsStr,
			studentsStr,
			isGroupsTableOpen: true,
			traningLang: training.lang ?? 'ru',
		}
	})
}
