import { useTranslation } from 'react-i18next'
import RestrictionsService from '../../../parts/services/RestrictionsService'
import TrainingCardForAdmin from '../trainingCard/TrainingCard/TrainingCardForAdmin'
import useTrainingsAdminStore from './zustand/store'
import CreateNewCard from 'ui/CreateNewCard/CreateNewCard'
import { AddTrainingModalController } from 'pages/trainings/admin/addTraining/AddTrainingModal/AddTrainingModalController'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import RowsContainer from 'ui/RowsContainer/RowsContainer'
import { EditTrainingModalController } from 'pages/trainings/admin/editTraining/EditTrainingModal/EditTrainingModalController'

// Список курсов для администратора
function TrainingsList() {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	const rawTrainings = useTrainingsAdminStore((state) => state.rawTrainings)
	const trainingsAdmin = useTrainingsAdminStore(
		(state) => state.trainingsAdmin
	)
	const selectedLang = useTrainingsAdminStore((state) => state.selectedLang)
	const searchStr = useTrainingsAdminStore((state) => state.searchStr)

	const canCreateNewTraining = RestrictionsService.useCanCreateNewTraining()

	let visibleTrainings = trainingsAdmin.filter(
		(t) => t.traningLang == selectedLang
	)
	if (searchStr) {
		visibleTrainings = visibleTrainings.filter((t) =>
			t.name.toLowerCase().includes(searchStr.toLowerCase())
		)
	}

	return (
		<>
			<RowsContainer>
				{visibleTrainings.map((training, i) => {
					return (
						<TrainingCardForAdmin
							key={training.id}
							trainingData={training}
							rawTraining={rawTrainings[i]}
						/>
					)
				})}
				{userRoles.isAdmin && canCreateNewTraining && (
					<CreateNewCard
						text={t('adminTrainings.addTrainingButtonCard')}
						onClick={AddTrainingModalController.open}
					/>
				)}
			</RowsContainer>
			{EditTrainingModalController.element}
		</>
	)
}

export default TrainingsList
