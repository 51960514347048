import React from 'react'
import AppUrls from 'parts/constants/pageUrl'
import { useTranslation } from 'react-i18next'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { TrainingListController } from '../../../../widgets/trainingsListAdmin/TrainingListController'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import SearchTraining from 'pages/trainings/admin/searchTraining/SearchTraining'

/** Страница со списком курсов для кураторов и менеджеров */
export default function TrainingsManagerCuratorPage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.Trainings().url,
						breadcrumbName: AppUrls.Trainings().name,
					},
				]}
				header={AppUrls.Trainings().name}
			/>
			<SearchTraining />
			<TrainingsArticle />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

function TrainingsArticle() {
	const trainingListRouter = TrainingListController.router()

	return (
		<ArticleContentWrapper
			center={
				trainingListRouter.status == 'loading' ||
				trainingListRouter.status == 'noTrainings'
			}
		>
			{trainingListRouter.status == 'noTrainings' && <NoTrainings />}
			{trainingListRouter.status == 'hasTrainings' &&
				trainingListRouter.element}
		</ArticleContentWrapper>
	)
}

function NoTrainings() {
	const { t } = useTranslation()

	return (
		<EmptyBlock text={t('adminTrainings.noTrainingCreatedInCuratorPage')} />
	)
}
